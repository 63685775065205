export const BASE_URL = "https://opalapi.sirusapps.com.au/api/v2/";

// Authentication
export const LOGIN_PARTNER = "partners/login";
export const SIGNUP_PARTNER = "partners/signup";
export const ONBOARD_PARTNER = "partners/onboard";

// Platform Partner Profile
export const GET_PROFILE = "partners/profile/get";
export const UPDATE_PROFILE = "partners/profile/update";
export const UPDATE_PROFILE_TERMS = "partners/profile/terms/update";
export const UPLOAD_COVER = "partners/cover/upload";
export const UPLOAD_AVATAR = "partners/avatar/upload";

// Offers
export const GET_OFFERS = "partners/offers/get";
export const UPLOAD_OFFER_COVER = "partners/offer/cover/upload";
export const CREATE_OFFER = "partners/offer/create";
